import {
  Box,
  capitalize,
  Card,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Correspondent,
  Interval,
  Meeting,
  MeetingType,
  Visitor,
} from "../api/graphql";
import MeetingTypeIcon from "../lib/MeetingTypeIcon";
import meetingTypeLabel from "../lib/meetingTypeLabel";
import { useCurrentCorrespondent } from "../SessionBoundary";
import { isCallJoinable } from "./EventCard";
import JoinCallButton from "./JoinCallButton";

type Props = {
  meeting: Pick<
    Meeting,
    "id" | "title" | "meetingType" | "status" | "unregisteredGuests"
  > & {
    interval: Pick<Interval, "startAt" | "endAt">;
    correspondents: Pick<
      Correspondent,
      "id" | "firstName" | "lastName" | "fullName"
    >[];
    primaryVisitor?: Pick<Visitor, "id"> | null;
  };
  sx?: SxProps<Theme>;
};

export default function LiveEventCard({ meeting, sx }: Props) {
  const { t } = useTranslation();
  const currentUser = useCurrentCorrespondent();
  const currentUserIsPrimaryVisitor =
    currentUser.id === meeting.primaryVisitor?.id;
  const isWebinar = meeting.meetingType === MeetingType.Webinar;
  // All events must be in a cancelable state, only webinar hosts can cancel
  // webinars, but inmates or visitors can cancel 1-on-1 meetings

  // Parsing participants
  const registeredParticipantsList = meeting.correspondents.filter(
    (c) => c.id !== currentUser.id
  );
  const unregisteredParticipantsList = meeting.unregisteredGuests || [];
  const allParticipantsList = [
    ...registeredParticipantsList.map((p) => p.fullName),
    ...unregisteredParticipantsList,
  ].join(", ");

  const participantName =
    (currentUser.__typename === "Inmate" && meeting.primaryVisitor?.id
      ? registeredParticipantsList.find(
          (r) => r.id === meeting.primaryVisitor?.id
        )?.fullName
      : registeredParticipantsList[0]?.fullName) || allParticipantsList[0];

  return (
    <Card variant="outlined" sx={sx || {}}>
      <Stack
        padding={3}
        spacing={1}
        direction={{ xs: "column", lg: "row" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack flex={6} direction="row" spacing={1} alignItems="center">
          <Box color="primary.main">
            <MeetingTypeIcon meetingType={meeting.meetingType} />
          </Box>
          <Typography marginLeft={1} variant="h3">
            {meeting.title
              ? t("{{meetingTitle}} is happening now", {
                  meetingTitle: meeting.title,
                })
              : isWebinar || !participantName
                ? capitalize(
                    t("You have a {{meetingType}} happening now", {
                      meetingType: meetingTypeLabel(meeting.meetingType),
                    })
                  )
                : allParticipantsList.length > 2
                  ? capitalize(
                      t(
                        "You have a {{meetingType}} happening now with {{participantName}} and others",
                        {
                          meetingType: meetingTypeLabel(meeting.meetingType),
                          participantName,
                        }
                      )
                    )
                  : capitalize(
                      t(
                        "You have a {{meetingType}} happening now with {{participantName}}",
                        {
                          meetingType: meetingTypeLabel(meeting.meetingType),
                          participantName,
                        }
                      )
                    )}
          </Typography>
        </Stack>
        <Box flex={1} maxWidth="300px">
          {(isWebinar ||
            currentUserIsPrimaryVisitor ||
            currentUser.__typename === "Inmate") &&
            isCallJoinable(meeting) && <JoinCallButton meeting={meeting} />}
        </Box>
      </Stack>
    </Card>
  );
}
