import { Box } from "@mui/material";
import React, { useMemo } from "react";
import featureEnabled from "../lib/featureEnabled";
import ProvideYourIdReminder from "../lib/ProvideYourIdReminder";
import useConnectionRequestRejections, {
  RejectedConnectionAlert,
} from "../lib/useConnectionRequestRejections";
import useIdRejectionStatus, {
  RejectedIDAlert,
} from "../lib/useIdRejectionStatus";
import useRemindMinorToProvideId from "../lib/useRemindMinorToProvideId";
import { useCurrentCorrespondent } from "../SessionBoundary";
import { isCallJoinable } from "./EventCard";
import { GetEventsWithVoiceCallsQuery } from "./GetEventsWithVoiceCalls.generated";
import LiveEventCard from "./LiveEventCard";

export default function UserAlerts({
  data,
}: {
  data?: GetEventsWithVoiceCallsQuery;
}) {
  const user = useCurrentCorrespondent();
  const rejectedId = useIdRejectionStatus();
  const rejectedConnections = useConnectionRequestRejections();

  const { showProvideIdReminder, reminderDueDate } = useRemindMinorToProvideId({
    user,
  });
  const liveMeetings = useMemo(
    () =>
      data?.currentCorrespondent?.meetings.edges
        .map((e) => e.node)
        .filter(isCallJoinable) || [],
    [data]
  );

  return (
    <>
      {/* Live meetings alert */}
      {featureEnabled("HOMEPAGE") &&
        liveMeetings.map((m) => (
          <Box key={m.id} mb={2}>
            <LiveEventCard meeting={m} />
          </Box>
        ))}
      {/* Remind minors to provide an ID */}
      {showProvideIdReminder && (
        <Box mb={2}>
          <ProvideYourIdReminder dueDate={reminderDueDate} />
        </Box>
      )}
      {/* Rejected ID or connection request */}
      {rejectedConnections.map((c) => (
        <Box key={c.id} mb={2}>
          <RejectedConnectionAlert id={c.id} name={c.inmate.fullName} />
        </Box>
      ))}
      {rejectedId && (
        <Box mb={2}>
          <RejectedIDAlert />
        </Box>
      )}
    </>
  );
}
