import { Avatar, Media } from "@ameelio/ui";
import { CalendarMonth } from "@mui/icons-material";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import { Box, Card, Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Connection as GQLConnection,
  ConnectionRestriction,
  ConnectionStatus,
  Correspondent,
} from "../api/graphql";
import { DateInterval } from "../lib/dateUtils";
import Link from "../lib/Link";
import VoiceCallsButton from "../VoiceCall/VoiceCallsButton";
import ConnectionStatusChip from "./ConnectionStatusChip";

type Connection = Pick<GQLConnection, "id" | "status"> & {
  activeRestrictions: Pick<ConnectionRestriction, "id" | "service">[];
  contact: Pick<
    Correspondent,
    | "firstName"
    | "lastName"
    | "fullName"
    | "unavailableUntil"
    | "canReceiveVoiceCalls"
  >;
};

export type Props = {
  showVoiceCall: boolean;
  showMessage: boolean;
  mayMakeVoiceCall: boolean;
  showScheduleEvent: boolean;
  nextVoiceCallSlot: DateInterval | null;
  connection: Connection;
};

export default function ConnectionCard({
  showVoiceCall,
  showMessage,
  showScheduleEvent,
  mayMakeVoiceCall,
  nextVoiceCallSlot,
  connection,
}: Props) {
  const { t } = useTranslation();

  const hasVoiceCallRestriction = Boolean(
    connection.activeRestrictions.find((r) => r.service === "VOICE_CALL")
  );

  return (
    <Card variant="outlined" sx={{ px: { xs: 1, sm: 2 }, py: 2 }}>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        gap={2}
        sx={{ maxWidth: "100%", overflow: "auto" }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar contact={connection.contact} />
          <Link to={`/contacts/${connection.id}`}>
            {`${connection.contact.fullName}`}
          </Link>
          {[ConnectionStatus.Pending, ConnectionStatus.Rejected].includes(
            connection.status
          ) && <ConnectionStatusChip status={connection.status} />}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2} component="nav">
          {showVoiceCall && (
            <VoiceCallsButton
              showButtonText
              mayMakeVoiceCall={mayMakeVoiceCall}
              hasRestriction={hasVoiceCallRestriction}
              nextVoiceCallSlot={nextVoiceCallSlot}
              connectionId={connection.id}
              contact={connection.contact}
            />
          )}
          {showMessage && (
            <Link to={`/messages/${connection.id}`}>
              <Media gap={0.5} image={<MessageOutlinedIcon />}>
                {t("Message")}
              </Media>
            </Link>
          )}
          {showScheduleEvent && (
            <Link
              state={{
                connectionId: connection.id,
                fromPath: `/contacts`,
                fromName: t("Contacts"),
              }}
              to="/events/new"
            >
              <Media gap={0.5} image={<CalendarMonth />}>
                {t("Schedule")}
              </Media>
            </Link>
          )}
        </Stack>
      </Box>
    </Card>
  );
}
