import { groupBy, notMissing } from "@ameelio/core";
import { Button } from "@ameelio/ui";
import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { CorrespondentFeature, MeetingStatus } from "../api/graphql";
import buildPageTitle from "../lib/buildPageTitle";
import { ListSkeleton } from "../lib/closet";
import featureEnabled from "../lib/featureEnabled";
import Link from "../lib/Link";
import Screen from "../lib/Screen";
import { ScreenSectionTitle } from "../lib/ScreenSection";
import SplitButton from "../lib/SplitButton";
import { relativeDate } from "../lib/timeFormats";
import useFeaturePermitted from "../lib/useFeaturePermitted";
import useHasActiveContact from "../lib/useHasActiveContact";
import useMayScheduleWebinars from "../lib/useMayScheduleWebinars";
import { useCurrentCorrespondent } from "../SessionBoundary";
import CancelMeetingDialog from "./CancelMeetingDialog";
import EventCard from "./EventCard";
import LeaveMeetingDialog from "./LeaveMeetingDialog";
import NoEventsScreen from "./NoEventsScreen";
import useEventsQuery from "./useEventsQuery";
import UserAlerts from "./UserAlerts";
import { unaccompaniedVisitAllowed } from "./utils";

export default function EventsScreen() {
  const { t } = useTranslation();
  const headerTitle = t("Events");
  const pageTitle = buildPageTitle(headerTitle);
  const user = useCurrentCorrespondent();

  const [meetingToCancel, setMeetingToCancel] = useState<
    | {
        description: string;
        meetingId: string;
      }
    | undefined
  >();

  const [meetingToLeave, setMeetingToLeave] = useState<
    | {
        description: string;
        meetingId: string;
      }
    | undefined
  >();

  const { data, error, refetch, fetchMore } = useEventsQuery();

  const allMeetings = useMemo(
    () =>
      data?.currentCorrespondent?.meetings.edges.map((e) => e.node).reverse() ||
      [],
    [data]
  );

  const upcomingMeetings = useMemo(
    () =>
      data?.currentCorrespondent?.meetings.edges
        .map((e) => e.node)
        .filter((meeting) =>
          [
            MeetingStatus.PendingApproval,
            MeetingStatus.Scheduled,
            MeetingStatus.Live,
          ].includes(meeting.status)
        ) || [],
    [data]
  );

  const historicalMeetings = useMemo(
    () =>
      data?.currentCorrespondent?.meetings.edges
        .map((e) => e.node)
        .filter(
          (meeting) =>
            ![
              MeetingStatus.PendingApproval,
              MeetingStatus.Scheduled,
              MeetingStatus.Live,
            ].includes(meeting.status)
        )
        .reverse() || [],
    [data]
  );

  // optional callback that will load older events
  const fetchOlder = useMemo(() => {
    const pageInfo = data?.currentCorrespondent?.meetings.pageInfo;
    return pageInfo?.hasPreviousPage
      ? () =>
          fetchMore({
            variables: {
              before: pageInfo.startCursor,
            },
          })
      : undefined;
  }, [data, fetchMore]);

  const mayScheduleEvents =
    useFeaturePermitted(CorrespondentFeature.ScheduleMeetings) &&
    unaccompaniedVisitAllowed(user);

  const mayRequestConnections = useFeaturePermitted(
    CorrespondentFeature.RequestConnections
  );
  const mayScheduleWebinars = useMayScheduleWebinars();
  const hasActiveContact = useHasActiveContact();

  if (error) throw error;
  if (!data?.currentCorrespondent) return <ListSkeleton />;

  const groupedUpcomingMeetings = groupBy(upcomingMeetings, (m) =>
    relativeDate(m.interval.startAt)
  );
  const groupedHistoricalMeetings = groupBy(historicalMeetings, (m) =>
    relativeDate(m.interval.startAt)
  );

  const groupedMeetings = groupBy(allMeetings, (m) =>
    relativeDate(m.interval.startAt)
  );

  return (
    <Screen
      title={headerTitle}
      showNotifications
      headerAction={
        ((mayScheduleEvents || mayScheduleWebinars) && hasActiveContact && (
          <SplitButton
            id="schedule-event-type"
            tooltipTitle={
              !hasActiveContact
                ? t(
                    "Once you have an approved contact you may be able to schedule events"
                  )
                : ""
            }
            buttons={[
              mayScheduleEvents
                ? {
                    label: t("Schedule event"),
                    to: "/events/new",
                    state: { fromPath: "/events", fromName: t("Events") },
                  }
                : undefined,
              mayScheduleWebinars
                ? {
                    label: t("Schedule webinar"),
                    to: "/events/new/webinar",
                    state: { fromPath: "/events", fromName: t("Events") },
                  }
                : undefined,
            ].filter(notMissing)}
            sx={{ flexGrow: 1 }}
          />
        )) ||
        (!hasActiveContact && (
          <Link button variant="contained" to="/contacts" sx={{ flexGrow: 1 }}>
            {t("Manage contacts")}
          </Link>
        ))
      }
    >
      {meetingToCancel && (
        <CancelMeetingDialog
          meetingToCancel={meetingToCancel}
          onClose={() => setMeetingToCancel(undefined)}
        />
      )}
      {meetingToLeave && (
        <LeaveMeetingDialog
          meetingToLeave={meetingToLeave}
          onClose={async () => {
            refetch();
            setMeetingToLeave(undefined);
          }}
        />
      )}
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <UserAlerts data={data} />
      {featureEnabled("HOMEPAGE") &&
        !!allMeetings.length &&
        Object.entries(groupedMeetings).map(([groupLabel, group]) => (
          <React.Fragment key={groupLabel}>
            <Typography variant="h3">{groupLabel}</Typography>
            {group && (
              <Stack spacing={2} marginTop={3} marginBottom={4}>
                {group.map((m) => (
                  <EventCard
                    key={m.id}
                    meeting={m}
                    onCancel={setMeetingToCancel}
                    onLeaveEvent={setMeetingToLeave}
                  />
                ))}
              </Stack>
            )}
          </React.Fragment>
        ))}
      {!featureEnabled("HOMEPAGE") && (
        <>
          {!!upcomingMeetings.length && (
            <>
              <ScreenSectionTitle title={t("Upcoming")} />
              {Object.entries(groupedUpcomingMeetings).map(
                ([groupLabel, group]) => (
                  <React.Fragment key={groupLabel}>
                    <Typography variant="h3">{groupLabel}</Typography>
                    {group && (
                      <Stack spacing={2} marginTop={3} marginBottom={4}>
                        {group.map((m) => (
                          <EventCard
                            key={m.id}
                            meeting={m}
                            onCancel={setMeetingToCancel}
                            onLeaveEvent={setMeetingToLeave}
                          />
                        ))}
                      </Stack>
                    )}
                  </React.Fragment>
                )
              )}
            </>
          )}
          {!!historicalMeetings.length && (
            <>
              {!!upcomingMeetings.length && <Divider sx={{ my: 5 }} />}
              <ScreenSectionTitle title={t("History")} />
              {Object.entries(groupedHistoricalMeetings).map(
                ([groupLabel, group]) => (
                  <React.Fragment key={groupLabel}>
                    <Typography variant="h3">{groupLabel}</Typography>
                    {group && (
                      <Stack spacing={2} marginTop={3} marginBottom={4}>
                        {group.map((m) => (
                          <EventCard
                            key={m.id}
                            meeting={m}
                            onCancel={setMeetingToCancel}
                            onLeaveEvent={setMeetingToLeave}
                            sx={{ backgroundColor: "#FAFAFA" }}
                          />
                        ))}
                      </Stack>
                    )}
                  </React.Fragment>
                )
              )}
            </>
          )}
        </>
      )}
      {fetchOlder && (
        <Box justifyContent="center" display="flex" flexDirection="row" p={2}>
          <Button onClick={fetchOlder}>{t("Load more")}</Button>
        </Box>
      )}
      {!allMeetings.length && (
        <NoEventsScreen
          cta={
            mayScheduleEvents && hasActiveContact
              ? "schedule"
              : mayRequestConnections &&
                  (unaccompaniedVisitAllowed(user) || !hasActiveContact)
                ? "connect"
                : "none"
          }
        />
      )}
    </Screen>
  );
}
